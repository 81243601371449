(function () {
	'use strict';
	angular
		.module('app')
		.directive('fieldMapAdvanced', ['$timeout', fieldMapAdvanced]);
	function fieldMapAdvanced($timeout) {
		return {
			restrict: 'EA',
			link: function (scope, element, attrs) {
				// Adjust scope in here
			},
			templateUrl: 'app/settings/source/fieldmap-advanced.html',
		};
	}
})();
